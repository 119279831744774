import * as React from "react"
import PropTypes from "prop-types"
import {Link} from "gatsby"
import {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {
    MenuIcon,
    XIcon,
} from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

const Header = ({siteTitle}) => (

        <Popover as="header" className="relative bg-custom-black sticky top-0 z-50">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6">
                        <div
                            className="flex justify-between items-center border-b-2 border-gray-900 py-6 md:justify-start md:space-x-10">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                {/*logo*/}
                            </div>
                            <div className="-mr-2 -my-2 md:hidden">
                                <Popover.Button
                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true"/>
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden md:flex space-x-10">

                                <a href="#trailer" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                    Trailer
                                </a>
                                <a href="#about" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                    About
                                </a>

                                <a href="#cast" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                    Cast & Crew
                                </a>

                                <a href="#interview" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                    Interview
                                </a>
                                <a href="#press" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                    Press
                                </a>

                            </Popover.Group>
                            <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                                <a href="https://www.facebook.com/oneofmoreshortfilm"
                                   className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                                    <StaticImage src="../images/facebook.png" alt="Facebook" />
                                </a>
                                <a
                                    href="https://instagram.com/oneofmoreshortfilm"
                                    className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
                                >
                                    <StaticImage src="../images/instagram.png" alt="Instagram" />
                                </a>
                            </div>
                        </div>
                    </div>

                    <Transition
                        show={open}
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            static
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                        >
                            <div
                                className="rounded-lg shadow-lg ring-1 ring-gray-500 ring-opacity-5 bg-gray-800 divide-y-2 divide-gray-800">
                                <div className="pt-5 pb-6 px-5">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            {/*logo*/}
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button
                                                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                                                <span className="sr-only">Close menu</span>
                                                <XIcon className="h-6 w-6" aria-hidden="true"/>
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-6 px-5 space-y-6">
                                    <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                        <a href="#trailer" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                            Trailer
                                        </a>

                                        <a href="#about" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                            About
                                        </a>
                                        <a href="#cast" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                            Cast & Crew
                                        </a>
                                        <a href="#interview" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                            Interview
                                        </a>
                                        <a href="#press" className="text-base font-medium text-gray-100 hover:text-gray-500">
                                            Press
                                        </a>

                                    </div>
                                    <div>
                                        <a
                                            href="https://www.facebook.com/oneofmoreshortfilm"
                                            className="w-full flex items-center justify-center px-4 py-2 text-base font-medium text-white"
                                        >
                                            <StaticImage src="../images/facebook.png" alt="Facebook" />
                                        </a>
                                        <a
                                            href="https://instagram.com/oneofmoreshortfilm"
                                            className="w-full flex items-center justify-center px-4 py-2 text-base font-medium text-white"
                                        >
                                            <StaticImage src="../images/instagram.png" alt="Instagram" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
